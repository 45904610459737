/**
 * Polyfill for IE9+ so we can use "closest"
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Browser_compatibility
 */
if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		var el = this;

		do {
			if (el.matches(s)) {
				return el;
			}
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

/*\
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  Revision #3 - July 13th, 2017
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
|*|  https://developer.mozilla.org/User:fusionchess
|*|  https://github.com/madmurphy/cookies.js
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path[, domain]])
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\*/

var docCookies = {
	getItem: function (sKey) {
		if (!sKey) {
			return null;
		}
		return (
			decodeURIComponent(
				document.cookie.replace(
					new RegExp(
						"(?:(?:^|.*;)\\s*" +
							encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
							"\\s*\\=\\s*([^;]*).*$)|^.*$"
					),
					"$1"
				)
			) || null
		);
	},
	setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
			return false;
		}
		var sExpires = "";
		if (vEnd) {
			switch (vEnd.constructor) {
				case Number:
					sExpires =
						vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
					/*
          Note: Despite officially defined in RFC 6265, the use of `max-age` is not compatible with any
          version of Internet Explorer, Edge and some mobile browsers. Therefore passing a number to
          the end parameter might not work as expected. A possible solution might be to convert the the
          relative time to an absolute time. For instance, replacing the previous line with:
          */
					/*
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; expires=" + (new Date(vEnd * 1e3 + Date.now())).toUTCString();
          */
					break;
				case String:
					sExpires = "; expires=" + vEnd;
					break;
				case Date:
					sExpires = "; expires=" + vEnd.toUTCString();
					break;
			}
		}
		document.cookie =
			encodeURIComponent(sKey) +
			"=" +
			encodeURIComponent(sValue) +
			sExpires +
			(sDomain ? "; domain=" + sDomain : "") +
			(sPath ? "; path=" + sPath : "") +
			(bSecure ? "; secure" : "");
		return true;
	},
	removeItem: function (sKey, sPath, sDomain) {
		if (!this.hasItem(sKey)) {
			return false;
		}
		document.cookie =
			encodeURIComponent(sKey) +
			"=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
			(sDomain ? "; domain=" + sDomain : "") +
			(sPath ? "; path=" + sPath : "");
		return true;
	},
	hasItem: function (sKey) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
			return false;
		}
		return new RegExp(
			"(?:^|;\\s*)" +
				encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
				"\\s*\\="
		).test(document.cookie);
	},
	keys: function () {
		var aKeys = document.cookie
			.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
			.split(/\s*(?:\=[^;]*)?;\s*/);
		for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
			aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
		}
		return aKeys;
	},
};

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/**
 * Print page section
 */
function printAircraftTablesElement(element_id) {
	var content = document.getElementById(element_id).innerHTML;
	var new_window = window.open("", "Print", "height=600,width=800");

	new_window.document.write(
		"<html><head> " +
			"<title>" +
			helpers.site_name +
			"</title>" +
			'<link rel="stylesheet" href="https://use.typekit.net/opi5btm.css">' +
			'<link rel="stylesheet" id="sage/css-css" href="' +
			helpers.site_url +
			'/wp-content/themes/vans-aircraft/dist/styles/main.css" type="text/css" media="all">' +
			"<style>" +
			"body { padding: 40px; } .button, .print-button { display: none; } .logo { display: block; width: 300px; margin-bottom: 4rem; } " +
			"</style>" +
			"</head>" +
			'<body class="print-popup">' +
			'<img class="logo" src="' +
			helpers.site_url +
			'/wp-content/themes/vans-aircraft/dist/images/logo-black.svg" alt="">' +
			content +
			"<script>window.onload = function() { window.print(); };</script>" +
			"</body>" +
			"</html>"
	);

	new_window.document.close();
	// new_window.focus();
	// new_window.print();
	// do not automatically close the window since styles may not be loaded quick enough
	// new_window.close();

	// return true;
}

(function ($) {
	"use strict";

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		common: {
			init: function () {
				// JavaScript to be fired on all pages

				/**
				 * Warn on external links
				 */
				// $('a:not([href="' + helpers.site_url + '"])').on('click', function() {
				//   var fancybox = $(this).attr('data-fancybox');
				//   var href = $(this).attr('href');

				//   if (typeof fancybox !== typeof undefined && fancybox !== false) {
				//     // false alarm, it's a popup link to allow it
				//     return true;
				//   } else if ($(this).hasClass('js-next-section-link') || href.charAt(0) === '#') {
				//     return true;
				//   } else {
				//     return confirm(
				//       "You are now leaving the Van's Aircraft website. Any info pertained to Van's Aircraft on 3rd party sites may not be accurate and/or represent the views and opinions of Van's Aircraft."
				//     );
				//   }
				// });

				/**
				 * Fitvids
				 */
				$("body").fitVids();

				/**
				 * Flickity slideshow for hero
				 */
        $(".js-hero-gallery, .js-hero-flexible").each(function(){
          if ($(this).children(".js-hero-gallery-slide").length > 1 || $(this).children(".js-hero-flexible-slide").length > 1) {
            var slide_timeout;
            var cell_selector;
            if ($(this).children(".js-hero-gallery-slide").length > 1) {
    					slide_timeout = $(".js-hero-gallery").data("timeout");
              cell_selector = ".js-hero-gallery-slide";
            } else if ($(".js-hero-flexible .js-hero-flexible-slide").length > 1) {
              slide_timeout = $(".js-hero-flexible").data("timeout");
              cell_selector = ".js-hero-flexible-slide";
            }
  					var $hero_gallery = $(this).flickity({
  						// options
  						draggable: true,
  						wrapAround: true,
  						adaptiveHeight: true,
  						setGallerySize: false,
  						pageDots: true,
  						prevNextButtons: true,
  						autoPlay: slide_timeout,
  						pauseAutoPlayOnHover: false,
  						cellSelector: cell_selector,
  						imagesLoaded: true,
  					});

  					// chrome won't autoplay videos in flickity so... trigger only when selected
  					var flickity_hero_gallery = $hero_gallery.data("flickity");

  					var playVideoOnSelect = function () {
  						var video =
  							flickity_hero_gallery.selectedElement.querySelector("video");

  						$hero_gallery.find("video").each(function (i, video) {
  							video.pause();
  						});

  						if (null !== video) {
  							video.play();
  						}
  					};

  					playVideoOnSelect();

  					$hero_gallery.on("select.flickity", playVideoOnSelect);
  				}
        });

				/**
				 * Flickity slideshow for First Flight
				 */
				if (
					$(".js-firstflight-gallery .js-firstflight-gallery-slide").length > 1
				) {
					var $gallery = $(".js-firstflight-gallery").flickity({
						// options
						draggable: true,
						wrapAround: true,
						adaptiveHeight: true,
						setGallerySize: true,
						pageDots: false,
						prevNextButtons: false,
						autoPlay: false,
						pauseAutoPlayOnHover: true,
						cellSelector: ".js-firstflight-gallery-slide",
						imagesLoaded: true,
					});

					// gallery controls
					if ($(".js-firstflight-control-gallery").length > 0) {
						$(".js-firstflight-control-gallery").flickity({
							asNavFor: ".js-firstflight-gallery",
							contain: true,
							cellAlign: "left",
							wrapAround: false,
							pageDots: false,
							prevNextButtons: false,
							autoPlay: false,
							pauseAutoPlayOnHover: true,
							cellSelector: ".js-firstflight-control-gallery-slide",
							imagesLoaded: true,
						});
					}

					var pauseVideos = function () {
						$gallery.find("video").each(function (i, video) {
							video.pause();
						});
					};

					var resizeSlides = function () {
						$gallery.flickity("resize"); // videos aren't sizing right
					};

					$gallery.on("select.flickity", pauseVideos);
					$gallery.on("settle.flickity", resizeSlides);
				}

				/**
				 * Isotope gallery
				 */
				$(".js-isotope-gallery").isotope({
					itemSelector: ".module-image-gallery__wrapper",
					percentPosition: true,
					masonry: {
						columnWidth: ".module-image-gallery__grid-sizer",
						gutter: ".module-image-gallery__gutter-sizer",
					},
				});

				/**
				 * "Next section" link
				 */
				$(".js-next-section-link").on("click", function (e) {
					e.preventDefault();

					var $parent_section = $(this).parents("section");
					var $next_section = $parent_section.next();
					var next_section_top = 0;
					var offset = 0;

					if ($next_section.offset() !== undefined) {
						next_section_top = $next_section.offset().top;

						$("html,body").animate(
							{ scrollTop: next_section_top - offset },
							800
						);
					}
				});

				/**
				 * Parallax elements
				 */
				if ($(".parallax").length > 0) {
					var rellax = new Rellax(".parallax");
				}

				/**
				 * Detect user is tabbing and add focus ring
				 * https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
				 */
				function handleFirstTab(e) {
					if (e.keyCode === 9) {
						// the "I am a keyboard user" key
						document.body.classList.remove("user-is-not-tabbing");
						window.removeEventListener("keydown", handleFirstTab);
					}
				}
				window.addEventListener("keydown", handleFirstTab);

				/**
				 * Primary Menu: remove links from top-level items with submenus
				 */
				$(
					'.js-main-menu li.menu-item-has-children > a[href="#"], .js-footer-menu li.menu-item-has-children > a[href="#"]'
				).removeAttr("href");

				// for MMenu, change the <a> to a <span> so the entire item will link to the submenu
				$('.js-main-menu-mobile li.menu-item-has-children > a[href="#"]').each(
					function () {
						var text = $(this).html();
						$(this).removeAttr("href");
						$(this).replaceWith("<span>" + text + "</span>");
					}
				);

				/**
				 * Mobile menu
				 */
				$(".banner__nav-primary-mobile").mmenu(
					{
						// options
						navbars: [
							{
								position: "bottom",
								content: [
									'<a href="' +
										helpers.menu_order_url +
										'" class="mm-menu__nav-button">' +
										helpers.menu_order_text +
										"</a>",
								],
							},
						],
						extensions: ["fullscreen"],
						keyboardNavigation: {
							enable: true,
							enhance: true,
						},
					},
					{
						// configuration
						offCanvas: {
							// pageNodetype: 'section',
							page: {
								selector: ".page-wrapper",
							},
						},
						classNames: {
							selected: "active",
						},
					}
				);

				var mobile_mmenu_api = $("#mobile-menu").data("mmenu");

				$(".js-toggle-mobile-menu").click(function () {
					if ($(this).attr("aria-expanded") === "false") {
						mobile_mmenu_api.open();
						$(this).attr("aria-expanded", "true");
					} else {
						mobile_mmenu_api.close();
						$(this).attr("aria-expanded", "false");
					}
				});

				/**
				 * Primary Menu: Hide on scroll
				 * https://www.html5rocks.com/en/tutorials/speed/animations/
				 */
				var header_lastKnownScrollY = 0;
				var header_lastUpdatedScrollY = 0;
				var header_scrollCheck = false;
				var header_visible = false;

				function isHidden(element) {
					var style = window.getComputedStyle(element);
					return style.display === "none";
				}

				function isMobile() {
					// since our MQs are in ems, instead of checking for the screen width we will instead look to see if the mobile menu toggle is visible
					return !isHidden($(".js-toggle-mobile-menu")[0]);
				}

				function headerUpdatePosition() {
					var currentScrollY = header_lastKnownScrollY;
					var $header = $(".banner");
					var headerHeight = $header.outerHeight();
					var scrollingDown = true;
					var nearTopParallaxFactor = 1.8; // 1 = 1 to 1 scroll, higher numbers => slower

					if (currentScrollY < header_lastUpdatedScrollY) {
						scrollingDown = false;
					}

					header_scrollCheck = false;

					// near the top?
					if (currentScrollY / nearTopParallaxFactor < headerHeight) {
						// if we are near to the top...
						var newPositionY = currentScrollY / nearTopParallaxFactor;

						if (currentScrollY === 0 || !header_visible) {
							//...and we are either at the very top of the page OR not already showing the header...
							// ...parallax the header
							$header.removeClass("has-transition");
							header_visible = false;
							$header.css("transform", "translateY(-" + newPositionY + "px)");
							$("body").addClass("menu-hidden");
						}
					} else {
						// we are not near the top so...
						$header.addClass("has-transition");

						if (scrollingDown) {
							// ...if scrolling down, hide the header
							header_visible = false;
							$header.css("transform", "translateY(-" + headerHeight + "px)");
							$("body").addClass("menu-hidden");
						} else {
							// ...if scrolling up, show the header
							header_visible = true;
							$header.css("transform", "translateY(0)");
							$("body").removeClass("menu-hidden");
						}
					}

					// update globals
					header_lastUpdatedScrollY = currentScrollY;
				}

				function headerOnScroll() {
					header_lastKnownScrollY = window.pageYOffset;

					if (!header_scrollCheck) {
						requestAnimationFrame(headerUpdatePosition);
						header_scrollCheck = true;
					}
				}

				window.addEventListener("scroll", headerOnScroll, false);

				/*
				 * Primary Menu: accessible menu dropdowns
				 * https://www.w3.org/WAI/tutorials/menus/flyout/#flyoutnavkbbtn
				 */
				var menuItems1 = document.querySelectorAll(
					".js-main-menu > li.menu-item-has-children"
				);
				var timerCloseMenuAction; // menu timer needs global scope

				function closeOpenMenuItems() {
					var menu = document.querySelector(".js-main-menu");
					var opennav = document.querySelector(
						'.js-main-menu li.menu-item-has-children[aria-expanded="true"]'
					);

					if (opennav) {
						menu.classList.remove("submenu-open");
						opennav.setAttribute("aria-expanded", "false");
					}
				}

				Array.prototype.forEach.call(menuItems1, function (el, i) {
					var btn = document.createElement("button");

					// add button to drop-down items
					var activatingA = el.querySelector("a");
					btn.innerHTML =
						'<span><span class="screen-reader-text">Show submenu for ' +
						activatingA.text +
						"</span></span>";
					activatingA.parentNode.insertBefore(btn, activatingA.nextSibling); // insert directly after <a>

					// set aria expanded intitial state
					el.setAttribute("aria-expanded", false);

					// handle mouseover
					el.addEventListener("mouseover", function (event) {
						clearTimeout(timerCloseMenuAction);
						closeOpenMenuItems();
						this.setAttribute("aria-expanded", "true");

						var menu = document.querySelector(".js-main-menu");
						menu.classList.add("submenu-open");
					});

					// handle mouseout
					el.addEventListener("mouseout", function (event) {
						timerCloseMenuAction = setTimeout(closeOpenMenuItems, 500);
					});

					// handle button click
					el.querySelector(
						"li.menu-item-has-children > button"
					).addEventListener("click", function (event) {
						var ariaExpanded = this.parentNode.getAttribute("aria-expanded");
						var menu = document.querySelector(".js-main-menu");

						if (ariaExpanded === "false") {
							this.parentNode.setAttribute("aria-expanded", "true");
							menu.classList.add("submenu-open");
						} else {
							this.parentNode.setAttribute("aria-expanded", "false");
							menu.classList.remove("submenu-open");
						}
						event.preventDefault();
					});

					// handle focus and blur events on drop-down items
					var links = el.querySelectorAll("a");
					Array.prototype.forEach.call(links, function (el, i) {
						el.addEventListener("focus", function () {
							if (timerCloseMenuAction) {
								clearTimeout(timerCloseMenuAction);
								timerCloseMenuAction = null;
							}
						});
						el.addEventListener("blur", function (event) {
							timerCloseMenuAction = setTimeout(closeOpenMenuItems, 500);
						});
					});
				});

				/**
				 * Hero gallery announcements: close event
				 */
				$(".js-announcement-close").on("click", function () {
					var $announcement = $(this).parents(
						".module-hero-gallery__announcement"
					);
					$announcement.addClass("module-hero-gallery__announcement--hide");
					var id = $announcement.attr("id");

					// set cookie
					docCookies.setItem("announcement-" + id, true, 60 * 60 * 12, "/"); // expires in 12 hours, applies to all paths
				});

				/**
				 * Hero gallery announcements: Hide announcement if previously hidden via cookie
				 */
				$(".js-hero-announcement").each(function () {
					var id = $(this).attr("id");
					if (!docCookies.getItem("announcement-" + id)) {
						$(this).fadeIn();
					}
				});

				/**
				 * Service page category (doc type) change event
				 */
				$(".js-service-cat").on("change", function () {
					var cat_value = $(this).val();
					var tag_value = $(".js-service-tag").val();
					var sort_value = $(".js-service-sort").val();
					var full_address = window.location.href;
					var page_address = full_address.split("?")[0];

					if (cat_value !== "") {
						window.location.href =
							page_address +
							"?aircraft=" +
							tag_value +
							"&doctype=" +
							cat_value +
							"&sort=" +
							sort_value;
					}
				});

				/**
				 * Service page tag (aircraft) change event
				 */
				$(".js-service-tag").on("change", function () {
					var tag_value = $(this).val();
					var sort_value = $(".js-service-sort").val();
					var full_address = window.location.href;
					var page_address = full_address.split("?")[0];

					if (tag_value !== "") {
						window.location.href =
							page_address +
							"?aircraft=" +
							tag_value +
							"&doctype=all" +
							"&sort=" +
							sort_value;
					}
				});

				/**
				 * Service page sort change event
				 */
				$(".js-service-sort").on("change", function () {
					var cat_value = $(".js-service-cat").val();
					var tag_value = $(".js-service-tag").val();
					var sort_value = $(this).val();
					var full_address = window.location.href;
					var page_address = full_address.split("?")[0];

					if (tag_value !== "") {
						window.location.href =
							page_address +
							"?aircraft=" +
							tag_value +
							"&doctype=" +
							cat_value +
							"&sort=" +
							sort_value;
					}
				});

				/**
				 * Helper function to determine is an element is in the viewport
				 */
				$.fn.isInViewport = function () {
					var elementTop = $(this).offset().top;
					var elementBottom = elementTop + $(this).outerHeight();
					var viewportTop = $(window).scrollTop();
					var viewportBottom = viewportTop + $(window).height();
					return elementBottom > viewportTop && elementTop < viewportBottom;
				};

				/**
				 * Gravity Forms: sticky second column
				 */
				if ($(".gform_wrapper .column-count-2-of-2").length === 1) {
					setInterval(function () {
						if (!$(".module-hero-simple").isInViewport()) {
							// we've scrolled down below the hero so add some padding
							$(".gform_wrapper .column-count-2-of-2").addClass("top-padding");
						} else {
							$(".gform_wrapper .column-count-2-of-2").removeClass(
								"top-padding"
							);
						}
					}, 226);
				}

				/**
				 * Responsive mod for Table Style 1 (.table-style-1)
				 */
				$(".table-style-1").wrap('<div class="table-scroll-wrap"></div>');

				/**
				 * Toggle display of menu search form
				 */
				var menu_search_toggle_buttons = document.querySelectorAll(
					".menu-toggle-search"
				);
				Array.prototype.forEach.call(
					menu_search_toggle_buttons,
					function (toggleButton) {
						toggleButton.addEventListener("click", function (event) {
							var target = event.target;
							var targetParent = target.parentNode;
							var form = targetParent.querySelector("form");
							var searchInput = form.querySelector(".search-field");

							// show form elements
							target.setAttribute("aria-expanded", "true");

							// set focus
							searchInput.focus();
						});
					}
				);

				/**
				 * Hide the menu search form
				 */
				function hideMenuSearchForm() {
					Array.prototype.forEach.call(
						menu_search_toggle_buttons,
						function (toggleButton) {
							// show form elements
							toggleButton.setAttribute("aria-expanded", "false");
						}
					);
				}

				/**
				 * Hide the search form if document click is outside the form
				 */
				document.addEventListener("click", function (event) {
					if (event.target.closest(".menu-search") === null) {
						hideMenuSearchForm();
					}
				});

				/**
				 * Handle Store menu links
				 */
				const oldStore = "https://shop.vansaircraft.com/";
				const newStore = "https://store.vansaircraft.com/";
				const cookieStorePrefName = "vans-store-location";
				const storePopupTriggers = document.querySelectorAll(
					".store-choice-trigger > a"
				);

				// open store choice popup
				function showStoreChoicePopup() {
					$.fancybox.open({
						src: "#store-choice-popup",
					});
				}

				// handler for Store menu links
				function handleStorePopupTriggerClick(event) {
					event.preventDefault();

					const cookieStorePref = docCookies.getItem(cookieStorePrefName);
					const currentURL = window.location.href;
					let url = "";

					// Do not show the popup if already on a store site
					if ( currentURL.indexOf(oldStore) !== -1 ) {
						url = oldStore;
					} else if ( currentURL.indexOf(newStore) !== -1 ) {
						url = newStore;
					}

					// Do not show the popup if the cookie is set
					if (url === "" && cookieStorePref !== null) {
						switch (cookieStorePref) {
							case "new":
								url = newStore;
								break;
							case "old":
								url = oldStore;
								break;
						}
					}

					// launch popup or go to store if URL is determined
					if (url === "") {
						// launch popup
						showStoreChoicePopup();
					} else {
						// go to preferred store
						window.location = url;
					}
				}

				// wire up the Store menu links
				storePopupTriggers.forEach((item) => {
					item.addEventListener("click", handleStorePopupTriggerClick);
				});

				// handler for store popup buttons
				function handleStoreButtonClick(type) {
					const checkbox = document.getElementById(
						"store-choice-popup-checkbox"
					);
					let url = "";

					if (checkbox.checked) {
						// save choice to cookie
						docCookies.setItem(
							cookieStorePrefName,
							type,
							60 * 60 * 24 * 7,
							"/"
						); // expires in 7 days hours, applies to all paths
					}

					switch (type) {
						case "new":
							window.location = newStore;
							break;
						case "old":
							window.location = oldStore;
							break;
					}
				}

				// wire up new store button
				document
					.getElementById("store-choice-popup-new")
					.addEventListener("click", () => {
						handleStoreButtonClick("new");
					});

				// wire up old store button
				document
					.getElementById("store-choice-popup-old")
					.addEventListener("click", () => {
						handleStoreButtonClick("old");
					});
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			},
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? "init" : funcname;
			fire = func !== "";
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === "function";

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire("common");

			// Fire page-specific init JS, and then finalize JS
			$.each(
				document.body.className.replace(/-/g, "_").split(/\s+/),
				function (i, classnm) {
					UTIL.fire(classnm);
					UTIL.fire(classnm, "finalize");
				}
			);

			// Fire common finalize JS
			UTIL.fire("common", "finalize");
		},
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
