/**
 * focus-trap node module
 * packaged via
 * https://wzrd.in/standalone/focus-trap@latest
 */
/* jshint ignore:start */
!(function(e) {
  if ('object' == typeof exports && 'undefined' != typeof module) module.exports = e();
  else if ('function' == typeof define && define.amd) define([], e);
  else {
    var t;
    (t =
      'undefined' != typeof window
        ? window
        : 'undefined' != typeof global
          ? global
          : 'undefined' != typeof self
            ? self
            : this),
      (t.focusTrap = e());
  }
})(function() {
  return (function e(t, n, r) {
    function o(u, a) {
      if (!n[u]) {
        if (!t[u]) {
          var c = 'function' == typeof require && require;
          if (!a && c) return c(u, !0);
          if (i) return i(u, !0);
          var f = new Error("Cannot find module '" + u + "'");
          throw ((f.code = 'MODULE_NOT_FOUND'), f);
        }
        var d = (n[u] = { exports: {} });
        t[u][0].call(
          d.exports,
          function(e) {
            var n = t[u][1][e];
            return o(n ? n : e);
          },
          d,
          d.exports,
          e,
          t,
          n,
          r
        );
      }
      return n[u].exports;
    }
    for (var i = 'function' == typeof require && require, u = 0; u < r.length; u++) o(r[u]);
    return o;
  })(
    {
      1: [
        function(e, t, n) {
          function r(e, t) {
            function n(e) {
              if (!F) {
                var t = { onActivate: e && void 0 !== e.onActivate ? e.onActivate : I.onActivate };
                return (F = !0), (A = !1), (k = document.activeElement), t.onActivate && t.onActivate(), d(), N;
              }
            }
            function r(e) {
              if (F) {
                var t = {
                  returnFocus: e && void 0 !== e.returnFocus ? e.returnFocus : I.returnFocusOnDeactivate,
                  onDeactivate: e && void 0 !== e.onDeactivate ? e.onDeactivate : I.onDeactivate
                };
                return (
                  s(),
                  t.onDeactivate && t.onDeactivate(),
                  t.returnFocus &&
                    setTimeout(function() {
                      i(k);
                    }, 0),
                  (F = !1),
                  (A = !1),
                  this
                );
              }
            }
            function c() {
              !A && F && ((A = !0), s());
            }
            function f() {
              A && F && ((A = !1), d());
            }
            function d() {
              if (F)
                return (
                  a && a.pause(),
                  (a = N),
                  g(),
                  i(v()),
                  document.addEventListener('focus', y, !0),
                  document.addEventListener('click', m, !0),
                  document.addEventListener('mousedown', p, !0),
                  document.addEventListener('touchstart', p, !0),
                  document.addEventListener('keydown', b, !0),
                  N
                );
            }
            function s() {
              if (F && a === N)
                return (
                  document.removeEventListener('focus', y, !0),
                  document.removeEventListener('click', m, !0),
                  document.removeEventListener('mousedown', p, !0),
                  document.removeEventListener('touchstart', p, !0),
                  document.removeEventListener('keydown', b, !0),
                  (a = null),
                  N
                );
            }
            function l(e) {
              var t = I[e],
                n = t;
              if (!t) return null;
              if ('string' == typeof t && ((n = document.querySelector(t)), !n))
                throw new Error('`' + e + '` refers to no known node');
              if ('function' == typeof t && ((n = t()), !n)) throw new Error('`' + e + '` did not return a node');
              return n;
            }
            function v() {
              var e;
              if (
                ((e =
                  null !== l('initialFocus')
                    ? l('initialFocus')
                    : O.contains(document.activeElement)
                      ? document.activeElement
                      : x[0] || l('fallbackFocus')),
                !e)
              )
                throw new Error("You can't have a focus-trap without at least one focusable element");
              return e;
            }
            function p(e) {
              I.clickOutsideDeactivates && !O.contains(e.target) && r({ returnFocus: !1 });
            }
            function m(e) {
              I.clickOutsideDeactivates || O.contains(e.target) || (e.preventDefault(), e.stopImmediatePropagation());
            }
            function y(e) {
              O.contains(e.target) ||
                (e.preventDefault(),
                e.stopImmediatePropagation(),
                'function' == typeof e.target.blur && e.target.blur(),
                L && E(L));
            }
            function b(e) {
              ('Tab' !== e.key && 9 !== e.keyCode) || h(e), I.escapeDeactivates !== !1 && o(e) && r();
            }
            function h(e) {
              if ((g(), e.target.hasAttribute('tabindex') && Number(e.target.getAttribute('tabindex')) < 0))
                return (L = e);
              e.preventDefault();
              var t = x.indexOf(e.target);
              return e.shiftKey
                ? i(e.target === w || x.indexOf(e.target) === -1 ? D : x[t - 1])
                : e.target === D
                  ? i(w)
                  : void i(x[t + 1]);
            }
            function g() {
              (x = u(O)), (w = x[0]), (D = x[x.length - 1]);
            }
            function E(e) {
              return e.shiftKey ? i(D) : void i(w);
            }
            var x = [],
              w = null,
              D = null,
              k = null,
              F = !1,
              A = !1,
              L = null,
              O = 'string' == typeof e ? document.querySelector(e) : e,
              I = t || {};
            (I.returnFocusOnDeactivate = !t || void 0 === t.returnFocusOnDeactivate || t.returnFocusOnDeactivate),
              (I.escapeDeactivates = !t || void 0 === t.escapeDeactivates || t.escapeDeactivates);
            var N = { activate: n, deactivate: r, pause: c, unpause: f };
            return N;
          }
          function o(e) {
            return 'Escape' === e.key || 'Esc' === e.key || 27 === e.keyCode;
          }
          function i(e) {
            e &&
              e.focus &&
              e !== document.activeElement &&
              (e.focus(), 'input' === e.tagName.toLowerCase() && e.select());
          }
          var u = e('tabbable'),
            a = null;
          t.exports = r;
        },
        { tabbable: 2 }
      ],
      2: [
        function(e, t, n) {
          function r(e) {
            function t(r, o) {
              if (r === e.documentElement) return !1;
              for (var i = 0, u = n.length; i < u; i++) if (n[i][0] === r) return n[i][1];
              o = o || e.defaultView.getComputedStyle(r);
              var a = !1;
              return 'none' === o.display ? (a = !0) : r.parentNode && (a = t(r.parentNode)), n.push([r, a]), a;
            }
            var n = [];
            return function(n) {
              if (n === e.documentElement) return !1;
              var r = e.defaultView.getComputedStyle(n);
              return !!t(n, r) || 'hidden' === r.visibility;
            };
          }
          t.exports = function(e, t) {
            t = t || {};
            var n = e.ownerDocument || e,
              o = [],
              i = [],
              u = r(n),
              a = ['input', 'select', 'a[href]', 'textarea', 'button', '[tabindex]'],
              c = e.querySelectorAll(a.join(','));
            if (t.includeContainer) {
              var f =
                Element.prototype.matches ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;
              a.some(function(t) {
                return f.call(e, t);
              }) && ((c = Array.prototype.slice.apply(c)), c.unshift(e));
            }
            for (var d, s, l = 0, v = c.length; l < v; l++)
              (d = c[l]),
                (s = parseInt(d.getAttribute('tabindex'), 10) || d.tabIndex),
                s < 0 ||
                  ('INPUT' === d.tagName && 'hidden' === d.type) ||
                  d.disabled ||
                  u(d, n) ||
                  (0 === s ? o.push(d) : i.push({ index: l, tabIndex: s, node: d }));
            var p = i
              .sort(function(e, t) {
                return e.tabIndex === t.tabIndex ? e.index - t.index : e.tabIndex - t.tabIndex;
              })
              .map(function(e) {
                return e.node;
              });
            return Array.prototype.push.apply(p, o), p;
          };
        },
        {}
      ]
    },
    {},
    [1]
  )(1);
});
/* jshint ignore:end */
